.drawBlue4 {
    /* stroke: #7F7F7F; */
    stroke: #FFFFFF;
    stroke-width: 1px;
    fill: transparent;
    stroke-dasharray: 250;
    stroke-dashoffset: 250;
    animation: animateBlue4 2.2s cubic-bezier(0.445, 0.050, 0.550, 0.950) 0.5s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
}

@keyframes animateBlue4 {
    to {
        stroke-dashoffset: 0;
    }
}

.drawBlue5 {
    /* stroke: #7F7F7F; */
    fill: transparent;
    opacity: 0;
    stroke-dasharray: 50;
    stroke-dashoffset: 50;
    animation: animateBlue5 2.2s cubic-bezier(0.445, 0.050, 0.550, 0.950) 0.5s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
}

@keyframes animateBlue5 {
    to {
        stroke-dashoffset: 0;
    }

    40% {
        opacity: 100;
        stroke-dashoffset: 50;
    }

    77% {
        opacity: 100;
        stroke-dashoffset: 0;
    }

    100% {
        opacity: 100;
        stroke-dashoffset: 0;
    }
}
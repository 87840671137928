.gradient-text {
    background: linear-gradient(264.51deg, #FFE580 4.38%, #FF7571 11.51%, #FF7270 25.06%, #EA5DAD 36.04%, #C2A0FD 47.63%, #9867F0 59.03%, #3BF0E4 69.96%, #33CE43 83.74%, #B2F4B6 95.62%);
    background-position: 58% 50%;
    background-size: 500%;
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-animation: gradient-shift 30s ease infinite;
    animation: gradient-shift 30s ease infinite;
    -webkit-text-fill-color: transparent;
}

@keyframes gradient-shift {
    0% {
        background-position: 58% 50%
    }

    25% {
        background-position: 100% 0%
    }

    75% {
        background-position: 10% 50%
    }

    to {
        background-position: 58% 50%
    }
}

.zoomPhoto {
    transition: transform 200ms ease-in-out;
}

.zoomPhoto:hover {
    transform: scale(1.03);
    transition: 200ms;
}

.zoomIcon {
    transition: transform 200ms ease-in-out;
}

.zoomIcon:hover {
    transform: scale(1.1);
    transition: 200ms;
}

.whatsLink {
    color: #46c756;
    transition: transform 200ms ease-in-out;
}

.whatsLink:hover {
    color: #79ff89;
}

.mailLink {
    color: #4285f4;
    transition: transform 200ms ease-in-out;
}

.mailLink:hover {
    color: #80b1ff;
}

.mapsLink {
    color: #fbbc04;
    transition: transform 200ms ease-in-out;
}

.mapsLink:hover {
    color: #ffde79;
}

.whatsLink-f {
    color: #7D8590;
    transition: transform 200ms ease-in-out;
}

.whatsLink-f:hover {
    color: #46c756;
}

.mailLink-f {
    color: #7D8590;
    transition: transform 200ms ease-in-out;
}

.mailLink-f:hover {
    color: #4285f4;
}

.mapsLink-f {
    color: #7D8590;
    transition: transform 200ms ease-in-out;
}

.mapsLink-f:hover {
    color: #fbbc04;
}
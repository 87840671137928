@font-face {
  font-family: 'Mona Sans';
  src:
    url('../public/Mona-Sans.woff2') format('woff2 supports variations'),
    url('../public/Mona-Sans.woff2') format('woff2-variations');
  font-weight: 200 900;
  font-stretch: 75% 125%;
}

html {
  font-family: 'Mona Sans';
  background-color: #0d1117;
}

body {
  font-family: 'Mona Sans';
  background-color: #0d1117;
  padding: 0px;
  margin: 0px;
}
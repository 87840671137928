/* slider styles */
.slider-container {
    position: relative;
    max-width: 450px;
    max-height: 400px;
    display: flex;
    justify-content: center;
}

.slider-image {
    width: 100%;
    max-height: 400px;
    object-fit: cover;
    transition: transform 0.5s ease-in-out;
    transform: translateX(0);
}

.slider-image.prev {
    transform: translateX(-100%);
}

.slider-image.next {
    transform: translateX(100%);
}

.slider-buttons {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.slider-button {
    position: relative;
    cursor: pointer;
    border: none;
    background: transparent;
    font-size: 2rem;
    color: #ffffffAA;
    z-index: 10;
}

.slider-button:focus {
    outline: none;
}

.slider-button::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.3);
    z-index: -1;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
}

.slider-button:hover::before {
    opacity: 1;
}

.left {
    margin-left: 20px;
}

.right {
    margin-right: 20px;
}
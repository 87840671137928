.drawEcg {
    stroke: #000;
    fill: transparent;
    stroke-dasharray: 249;
    stroke-dashoffset: 500;
    animation: animateEcg 10s linear 0.5s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
}

@keyframes animateEcg {
    to {
        stroke-dashoffset: 0;
    }
}

.drawArt {
    stroke: #000;
    fill: transparent;
    stroke-dasharray: 500;
    stroke-dashoffset: 0;
    animation: animateArt 10s linear 0.5s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
}

@keyframes animateArt {
    to {
        stroke-dashoffset: -600;
    }
}